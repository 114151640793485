<template>
  <v-expansion-panels focusable class="mt-4">
    <v-expansion-panel v-for="(text, heading) in rules" :key="heading">
      <v-expansion-panel-header color="#934345"
        >{{ heading }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="(sectionParagraph, i) in text" :key="i">
          <img
            v-if="sectionParagraph.includes('http')"
            :src="`${sectionParagraph}`"
            alt=""
          />
          <p
            v-if="!sectionParagraph.includes('http')"
            class="section-paragraph"
            v-html="$options.filters.hashBold(sectionParagraph)"
          ></p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'Rules',

  data() {
    return {
      rules: {
        'General Rules': [
          'Достъпни за избор са играчи от 8-те топ първенства със сходни програми за сезона - #Англия, Испания, Италия, Франция, Германия, Португалия, Холандия, Турция#;',
          'В играта #няма бюджети и цени на играчите.# Избирането на играчи става на draft принцип;',
          'Единственият източникът за статистики и точкуване е WhoScored.com (само десктоп версията)',
          'Единственият източник за клубна принадлежност и позиция на играчи – TransferMarkt.com.',
          'Имайте предвид, че позициите на играчите се ъпдейтват постоянно в зависимост от това къде биват пускани от треньора. При трансфер се гледа #моментната позиция на играча#, а не тази, с която е започнал сезона;',
          '(Пример: Яго Аспас е взет на драфта като крило за Отбор А. Играе 20 кръга и точкуването му е за крило. След Кръг 20 Аспас е продаден от Отбор А и Отбор Б иска да го купи. Да, но според ТрансферМаркт той вече е нападател и ако Отбор Б го иска, може да го вземе само като такъв)',
          'Същото е с WhoScored – наградите за МОМ понякога биват сменяни след някой и друг ден, а понякога се случват и други корекции. Междинни точки могат да бъдат изчислени след мачовете за даден ден, но официалните точки за кръга се обявяват в края на съответния кръг. Всички промени случили се преди края на кръга ще бъдат отчетени, но щом официалните резултати са публикувани, те не могат да бъдат променяни.',
          'Сезон 21/22 ще се проведе в #42 седмици/кръга. # За точкуването в един кръг се вземат предвид всички получени точки в периода #Вторник-Понеделник (вкл); #',
          'Първият кръг започва във #Вторник 18.08.2020#, а последният завършва в #Неделя 23.05.2020#; Драфтът ще се проведе в седмицата преди първи кръг, в удобно за всички участници време; Сезонът се разделя на два полусезона. Първият полусезон приключва на 04.01.2021, а вторият полусезон започва на 04.01.2021.',
          '#Входна такса за мениджър - 50лв. # Допуска се разсрочено плащане. Награден фонд за всяка група:',
          '- 1 място – #220лв.#',
          '- 2 място – #110лв.#',
          '- 3 място – #60лв.#',
          'Победителят в Купата получава и #+100лв.#',
        ],
        'Teams & Players': [
          'Мениджърите ще бъдат разпределени в две независими групи – #лига Пеле и лига Марадона.# Всяка лига ще има по #11 отбора и отделен драфт.#',
          'Всеки тим се състои от #16 играчи# (1 вратар, 2 леви бека, 2 десни бека, 2 централни защитника, 2 леви крила, 2 десни крила, 2 централни халфа и 3 нападатели). #Всички# играчи участват в точкуването за кръга # (няма резерви);#',
          'Играчи #не могат да бъдат дублирани# (веднъж избран, даден играч може да съществува само в 1 отбор, докато мениджърът не реши да го трансферира извън клуба);',
          'Всеки отбор има #капитан, който удвоява точките си за кръга.# Капитанът може да се сменя веднъж на кръг и смяната влиза в сила за следващия календарен кръг;',
          'Всеки отбор има и #вицекапитан#, който се активира ако капитанът не запише и една игрова минута. При седмица с 2 футболни кръга, вицекапитанът се активира само ако капитанът, #не запише и една минута и в двата мача.#',
          'Веднъж за полусезон може да се селектира #троен капитан - той утроява точките си за кръга.# Селекцията му се обявява за следващия календарен кръг;',
        ],
        Scoring: [
          'https://ff-legends.com/rules_s3.PNG',
          'За точки #“starter”# се класират играчи, които са изиграли поне #60 минути#',
          'За точки #“sub”# се класират играчи, които не са започнали титуляри или са изиграли по-малко от 60мин. Ако играч влезне като принудителна смяна и изиграе поне 60мин се класира за точки #“starter”#',
          'Играчът трябва да изиграе поне 45мин, за да се класира за точки #“победа”#',
          'За да се класира за точки #“clean sheet”# играчът трябва да изиграе поне 45мин, в който период тимът му да не допусне гол (не се броят получените голове във времето, когато играчът не е на терена)',
          'Аналогично, за да бъдат отнети точки за #“3 allowed”#, играчът трябва да е бил на терена поне 45мин и головете да са получени, докато той е на терена. Ако играчът е допуснал 6 гола, негативните точки се удвояват.',
          'За определяне на saves за точките #„GK saves x3”# се гледат ударите on target на противниковия отбор в WhoScored. Ако вратарят е направил 6 saves взима бонуса два пъти.',
          'При #match rating над 8.0# се дава допълнителна точка, независимо от позицията на играча. Ако играчът е MOM не взима бонус и за match rating 8.5+.',
          'Ако играч получи #жълт картон или червен картон,# докато е на скамейката, той ще получи съответните си -1 и -3 точки, въпреки че не е влезнал в игра.',
        ],
        Transfers: [
          'Позволени са #два (2) трансфера на рунд.# Трансферите се заявяват до Понеделник и важат за следващата седмица; Трансфери могат да се правят през цялата седмица.',
          'Ако направите 1 трансфер за седмица, #натрупвания няма да има.# На следващата седмица отново ще имате 2 трансфера;',
          'Ако играч има 2 траснфера, може да избере да извърши първо единия, а по-късно същата седмица и другия траснфер;',
          'Ако играч има 2 трансфера за седмицата #и направи само 1#, на следващата седмица отново си запазва правото на 2 трансфера.',
          'Трансфери могат да се осъществяват #както на “свободни агенти”, така и чрез договорка между двама мениджъри,# при условие, че разменените играчи са на една и съща позиция;',
          'По веднъж на полусезон може да използвате #transfer wildcard,# който ще ви позволи да направите #четири трансфера# накуп; Wildcard-ът #заменя# седмичните трансфери, а не ги натрупва (така, ако изтеглите wildcard, ще имате 4 трансфера, независимо че по принцип имате 2 за дадената седмица);',
          'Ако играч вече е извършил стандартен седмичен траснфер, той няма право да използва wildcard същата седмица;',
          'Ако играч използва wildcard, трябва да обяви всички траснфери веднага;',
          'Ако клуб привлече “свободен агент”, #друг мениджър може да привлече освободения от него играч.# Ако повече от 1 мениджър желае да привлече освободения играч, предимството е на принципа #“first come - first served”.# Актуални новини и трансфери ще бъдат обявявани на сайта и в затворена фейсбук група;',
          'Ако даден играч в реалния футбол е #изходящо трансфериран в някое първенство извън горепосочените осем#, то мениджърът му ще има право да го смени, независимо дали вече е използвал трансферът си за седмицата (т.нар. free transfer); Това #НЕ важи ако играчът е преминал в друг клуб от горепосочените осем първенства.#',
        ],
        Drafting: [
          'Драфтовете #трябва да се проведат поне седмица преди началото на първенството,# за да може да са финализирани максимално много трансфери в реалния футбол и да се изпревари стартът на най-ранните първенства в играта;',
          'Времетраенето на драфтовете зависи изцяло от участниците, но предвид обема на играчите (по 16 на отбор) и възможността последните драфт pick-ове да са малко по-трудни #се очаква да си освободите поне 2 часа;#',
          'Силно е #препоръчително участниците да са предварително подготвени за драфта,# защото в хода на жребия много от техните предварителни идеи могат да се окажат “взети” от други участници. Широкото познаване на играчи от 8-те първенства ще ускори целия процес на избор;',
          'За да се избегнат #спорове относно позицията на даден играч,# информация за това ще бъде вземана от transfermarkt.com;',
          'Позицията на играча може да бъде единствено главната позиция записана в профила на играча в TransferMarkt',
          '#ВАЖНО - изключение правят Меси, Неймар, Кристиано и Мбаппе, които ще бъдат считани за нападатели независимо какво казва ТрансферМаркт!#',
          'За останалите играчи в TransferMarkt може да гледате:',
          'GK ',
          'DL – Left back / Left defender',
          'DR – Right back / Right defender',
          'DC – Central defender',
          'LW – Left winger / Midfielder Left',
          'RW – Right winger / Midfielder Right',
          'MC – Defensive midfielder, Central Midfielder, Attacking midfielder',
          'FW – Centre forward, Second Striker',
          'В началото на драфта всеки участник ще получи номер. Ще бъде използван #random generator, който ще определи редът на участниците;#',
          'Така например при драфт с десет мениджъри, Мениджър 1 получава избор 1, Мениджър 2 е с избор 2 и така до избор 10. След като Мениджър 10 вземе избор 10, той взима и избор 11. Аналогично избор 12 е за Мениджърът, който е имал избор 9 и така до Мениджър 1, който получава избор 20 и след това избор 21. Това продължава до избор 160. ',
          'След последния избор всеки мениджър избира капитана си за 1 кръг.',
        ],
        'FF Legends Cup (Trofeo Legendarico)': [
          'В Купата ще вземат участие #всички отбори,# независимо от Лигата или класирането си. Там е възможно да се паднат #два отбора с еднакви футболисти.#',
          'Форматът на Купата е #групи + елиминации.# Отборите се разделят в четири #групи  и играят всеки срещу всеки в 1 мач.# След изиграването на мачовете #ще продължат първите 16 отбора (от всяка група по 4).#',
          'При равни точки в групите #първо се гледа голова разлика, а след това – директният двубой;#',
          'Във фазата на елиминациите ще имаме осминафинали, четвъртфинали, полуфинали и финал, #всичките по два мача, без финала.#',
          'При равни общи точки в елиминациите се гледат #повечето точки на чужд терен;#',
          'Отборът за Купата се състои от 8 човека – #1 вратар, 1 ляв бек, 1 десен бек, 1 централен защитник, 1 ляво крило, 1 дясно крило, 1 централен полузащитник и 1 нападател.#',
          '#Без резерви и капитани.#',
          '#Кумулативните точки на 8-те играча срещу 8-те противника# определят крайния резултат.',
          'Победителят в Купата получава #+100лв, независимо от позицията му в класирането на Лигата.#.',
        ],
        'FFL Head-to-Head League': [
          'H2H Лигата е допълнителна опция за състезание във FFL, което е напълно пожелателно, и се основава на директни двубой между всички включили се мениджъри.',
          'Входната такса за първенството е 20лв, а наградният фонд всяка година ще се определя по формулата:',
          '#Х мениджъри по 20 лв = тотал бюджет#',
          '- 1 място – 150лв.',
          '- 2 място – 80лв.',
          '- 3 място – 40лв.',
          'Програмата се разпростира в #34 кръга,# в които #всички отбори ще играят помежду си по 2 пъти;#',
          'Преди всяка игрова седмица, участниците в Х2Х избират Формация и Състав от 11 играчи.',
          'Позволените формации са - 3-5-2; 3-4-3; 4-4-2; 4-5-1; 4-3-3; 5-4-1; 5-3-2 и 5-2-3;',
          'Формациите ще са като в реалния футбол и #няма да ви ограничават по постове,# а по-скоро по линии. (Например, ако изберете формация 4-4-2, четирите ви халфа може да са двете леви и двете десни крила без нито един централен халф).',
          'За точки за двубоя се считат #сборът на точките на избраните 11 за кръга. Няма капитански бонус',
          'За всяка победа се дават #3т.,# за равен #1т.# И #0т.# За загуба;',
          'Гледат се точките след 34 кръга. При равни точки първо значение има #головата разлика,# а след това #головата разлика от двата директни двубоя)#',
        ],
      },
    };
  },
  filters: {
    hashBold: function (value) {
      if (!value) return '';
      const string = value.toString();
      const hashReg = /#(.*?)#/gm;
      const result = string.replace(hashReg, '<strong>$1</strong>');
      return result;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../common/breakpoints.scss';

.v-expansion-panel-header {
  color: white !important;
}

.section-paragraph {
  font-size: 1rem;
  margin: 15px 25px;
  line-height: 22px;
  @media #{$mobile} {
    margin: 10px 5px;
  }

  strong {
    font-weight: bold;
  }

  img {
    width: 60%;
  }
}
</style>
